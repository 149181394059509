import React from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import "./index.css";
import { Link } from "react-router-dom";

const Dashboard = () => {
  // const userData = getUserData();
  // let brand = userData.brandProfileData;
  // let user = userData.userProfileData;
  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Card
          style={{
            backgroundColor: "#1752AA",
            color: "white",
            marginTop: 20,
            textAlign: "center",
          }}
        >
          <Card.Body>
            <Row>
              <Col>
                <h5>Brand Name : Oitpl</h5>
              </Col>
              <Col>
                <h5>Mobile Number : 9811937572</h5>
              </Col>
            </Row>
            {/*<Row>
              <Col>
                <h5>User Name : user.firstName + " " + user.lastName</h5>
              </Col>
              <Col>
                <h5>Mobile Number : user.mobileNo</h5>
              </Col>
            </Row>*/}
          </Card.Body>
        </Card>

        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Garment View</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/view-garment"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Add Garment</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/add-garment"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>View AppServices</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/view-app-services"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                backgroundColor: "#1752AA",
                color: "white",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Card.Body>
                <Card.Title>Add AppService</Card.Title>
                <Card.Text></Card.Text>
                <Link
                  to="/app-service"
                  className="move-btn-a move-btn-mu center-text"
                  variant="primary"
                >
                  Go
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
