import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Root from "../authLayout/Root";
import AuthLayout from "../authLayout/AuthLayout";
import Authentication from "../authLayout/Authentication";
import Dashboard from "../pages/Dashboard";
import AddGarment from "../pages/AddGarment";
import GarmentView from "../pages/GarmentView";
import AddAppService from "../pages/AddAppService";
import AppServicesView from "../pages/AppServicesView";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/" element={<Authentication />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/add-garment" element={<AddGarment />} />
        <Route path="/view-garment" element={<GarmentView />} />
        <Route path="/app-service" element={<AddAppService />} />
        <Route path="/view-app-services" element={<AppServicesView />} />
      </Route>
      <Route path="logout" element={<Logout />} />
    </Route>
  )
);

export default router;
